import { Component, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";
import { AppComponent } from "../app.component";
import { UserAuthenticatedService } from "../services/user-authenticated.service";

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "dashboard",
  },
  {
    path: "/components/demandes_formation",
    title: "Contrats de formation",
    type: "sub",
    icontype: "content_paste",
    collapse: "demandes",
    children: [
      { path: "add", title: "Ajouter un contrat", ab: "" },
      { path: "list", title: "Liste des contrats", ab: "" },
    ],
  },
  {
    path: "/suivie_formation",
    title: "Suivi des formations",
    type: "link",
    icontype: "date_range",
  },
  {
    path: "/statistiques_kpi",
    title: "Statistiques KPI",
    type: "sub",
    icontype: "timeline",
    collapse: "statistiques_kpi",
    children: [
      { path: "personnes_formees", title: "Personnes Formées", ab: "" },
      { path: "hommes_jours", title: "Homme/jour", ab: "" },
      { path: "profils_formes", title: "Profils formés", ab: "" },
      {
        path: "profils_formes_prerequis",
        title: "Personnes formées par prérequis",
        ab: "pf",
      },
      { path: "tranche_ages", title: "Tranche d'ages", ab: "" },
      { path: "type_formation", title: "Type de formation", ab: "" },
      { path: "domaine_formation", title: "Domaine de formation", ab: "" },
      { path: "subvension", title: "Subvension", ab: "" },
      { path: "repartition_sde", title: "Répartition SDE", ab: "" },
      { path: "repartition_cde", title: "Répartition CDE", ab: "" },
      { path: "repartition_pays", title: "Répartition pays", ab: "" },
      { path: "formateurs_cde", title: "Formateurs", ab: "" },
      { path: "formateurs_profils", title: "Profil formateur", ab: "fp" },
      {
        path: "formateurs_experience",
        title: "Expérience formateur",
        ab: "fe",
      },
      /*
      { path: "formations", title: "Formations", ab: "" },
      { path: "themes_formation", title: "Thème de formation", ab: "" },
      { path: "contrats", title: "Contrat", ab: "" },
      { path: "types_formation", title: "Type de formation", ab: "" },
      { path: "reseau", title: "Reseau", ab: "" },
      { path: "couts_formation", title: "Coûts de formation", ab: "" },
      { path: "langues", title: "Langue", ab: "" },
      { path: "regions_pays", title: "Régions & Pays", ab: "" },
      { path: "apprenants", title: "Apprenants", ab: "" },
      { path: "formateurs", title: "Formateurs", ab: "" },*/
    ],
  },
  {
    path: "/components/domaines_formation",
    title: "Domaines de formation",
    type: "sub",
    icontype: "grid_on",
    collapse: "demaines-formation",
    children: [
      { path: "add", title: "Ajouter un domaine", ab: "" },
      { path: "list", title: "Liste des domaines", ab: "" },
    ],
  },
  {
    path: "/components/structures",
    title: "Les partenaires",
    type: "sub",
    icontype: "domain",
    collapse: "structures",
    children: [
      { path: "add", title: "Ajouter un partenaire", ab: "" },
      { path: "list", title: "Liste des partenaires", ab: "" },
    ],
  },
  {
    path: "/components/countries",
    title: "Les pays",
    type: "sub",
    icontype: "flag",
    collapse: "countries",
    children: [
      { path: "add", title: "Ajouter un pays", ab: "" },
      { path: "list", title: "Liste des pays", ab: "" },
    ],
  },
  {
    path: "/components/agents",
    title: "Points focaux",
    type: "sub",
    icontype: "group",
    collapse: "points-focaux",
    children: [
      { path: "add", title: "Ajouter un utilisateur", ab: "" },
      { path: "list", title: "Liste des utilisateurs", ab: "" },
    ],
  } /* ,,{
        path: '/maps',
        title: 'Maps',
        type: 'sub',
        icontype: 'place',
        collapse: 'maps',
        children: [
            {path: 'google', title: 'Google Maps', ab:'GM'},
            {path: 'fullscreen', title: 'Full Screen Map', ab:'FSM'},
            {path: 'vector', title: 'Vector Map', ab:'VM'}
        ]
    } /* ,{
        path: '/forms',
        title: 'Forms',
        type: 'sub',
        icontype: 'content_paste',
        collapse: 'forms',
        children: [
            {path: 'regular', title: 'Regular Forms', ab:'RF'},
            {path: 'extended', title: 'Extended Forms', ab:'EF'},
            {path: 'validation', title: 'Validation Forms', ab:'VF'},
            {path: 'wizard', title: 'Wizard', ab:'W'}
        ]
    },{
        path: '/tables',
        title: 'Tables',
        type: 'sub',
        icontype: 'grid_on',
        collapse: 'tables',
        children: [
            {path: 'regular', title: 'Regular Tables', ab:'RT'},
            {path: 'extended', title: 'Extended Tables', ab:'ET'},
            {path: 'datatables.net', title: 'Datatables.net', ab:'DT'}
        ]
    }/*
   /* 
    {
    
        path: '/components',
        title: 'Components',
        type: 'sub',
        icontype: 'apps',
        collapse: 'components',
        children: [
            {path: 'buttons', title: 'Buttons', ab:'B'},
            {path: 'grid', title: 'Grid System', ab:'GS'},
            {path: 'panels', title: 'Panels', ab:'P'},
            {path: 'sweet-alert', title: 'Sweet Alert', ab:'SA'},
            {path: 'notifications', title: 'Notifications', ab:'N'},
            {path: 'icons', title: 'Icons', ab:'I'},
            {path: 'typography', title: 'Typography', ab:'T'}
        ]
    },{
        path: '/tables',
        title: 'Tables',
        type: 'sub',
        icontype: 'grid_on',
        collapse: 'tables',
        children: [
            {path: 'regular', title: 'Regular Tables', ab:'RT'},
            {path: 'extended', title: 'Extended Tables', ab:'ET'},
            {path: 'datatables.net', title: 'Datatables.net', ab:'DT'}
        ]
    },{
        path: '/maps',
        title: 'Maps',
        type: 'sub',
        icontype: 'place',
        collapse: 'maps',
        children: [
            {path: 'google', title: 'Google Maps', ab:'GM'},
            {path: 'fullscreen', title: 'Full Screen Map', ab:'FSM'},
            {path: 'vector', title: 'Vector Map', ab:'VM'}
        ]
    },{
        path: '/widgets',
        title: 'Widgets',
        type: 'link',
        icontype: 'widgets'

    },{
        path: '/charts',
        title: 'Charts',
        type: 'link',
        icontype: 'timeline'

    },*/,
];
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ps: any;
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  constructor(
    protected appComponent: AppComponent,
    protected userAuth: UserAuthenticatedService
  ) {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  expandOrCollapseMenu(id) {
    let parent = document.getElementById(id + "-p");
    let child = document.getElementById(id);
    parent.ariaExpanded = parent.ariaExpanded === "true" ? "false" : "true";
    child.style.height =
      child.style.height === "0px" || child.style.height === "" ? "100%" : "0";
  }
}
