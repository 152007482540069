import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UserAuthenticatedService {

  public data:any = null;

  constructor() { }

  public login(data:any) {
    this.data = data;
  }

  public logout() {
    this.data = null;
  }

  found():boolean {
    return this.data !== null;
  }

  notFound():boolean {
    return ! this.found() ;
  }

  can(roleNames:string[] | string):boolean {
    let roles:string[] = [];
    if(typeof roleNames == 'string') {
      roleNames = [roleNames];
    }
    else {
      roles = roleNames ;
    }
    return (this.data !== null);
  }

  authId():string {
    if(! this.found()) {
      return "";
    }
    return this.data.id ;
  }
  username():string {
    if(! this.found()) {
      return "";
    }
    return this.data.user.username ;
  }
  email():string {
    if(! this.found()) {
      return "";
    }
    return this.data.user.email ;
  }
  userId():string {
    if(! this.found()) {
      return "";
    }
    return this.data.user.id ;
  }
  fullName():string {
    if(! this.found()) {
      return "";
    }
    return this.data.user.last_name + " " + this.data.user.first_name ;
  }
  structureName():string {
    if(! this.found()) {
      return "";
    }
    return this.data.structure.raison_sociale ;
  }
  country():string {
    if(! this.found()) {
      return "";
    }
    return this.data.structure.pays.nom ;
  }
  editStructures() {
    if(! this.found()) {
      return false;
    }
    return this.data.role_metier == "admin"
  }
  editAgents() {
    if(! this.found()) {
      return false;
    }
    return this.data.role_metier == "admin"
  }
  consultToutesDemandesFormation() {
    if(! this.found()) {
      return false;
    }
    return this.data.role_metier == "admin"
  }
  consultToutesDemandesAppelOffre() {
    if(! this.found()) {
      return false;
    }
    return this.data.role_metier == "admin"
  }
  consultToutesReponsesAppelOffre() {
    if(! this.found()) {
      return false;
    }
    return this.data.role_metier == "admin"
  }
  demandeFormation() {
    if(! this.found()) {
      return false;
    }
    return this.data.structure.typeStructure.nom_type == "UDC"
  }
  demandeAppelOffres() {
    if(! this.found()) {
      return false;
    }
    return this.data.structure.typeStructure.nom_type == "UDC"
  }
  repondAppelOffres() {
    if(! this.found()) {
      return false;
    }
    return this.data.structure.typeStructure.nom_type == "CDE"
  }
}
