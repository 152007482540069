<footer class="footer">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="#"> Documentations</a>
        </li>
        <li>
          <a href="https://ancee-racee.org/" target="_blank">
            A propos du RACEE
          </a>
        </li>
        <li>
          <a
            href="https://ancee-racee.org/actualite-evenements/"
            target="_blank"
          >
            Actualités
          </a>
        </li>
        <li>
          <a href="https://ancee-racee.org/ressources/" target="_blank">
            Ressources
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy; Copyright 2023 - Réseau des Centres d'Excellence en Electricité -
      <a
        href="mailto:decynsire@gmail.com"
        target="_blank"
        rel="noopener"
        class=""
        >Powered</a
      >
      by
      <a
        href="mailto:decynsire@gmail.com"
        target="_blank"
        rel="noopener"
        class=""
        >Decynsire</a
      >
    </div>
  </div>
</footer>
