export const environment = {
  production: false,
  //mainApiUrl: 'http://127.0.0.1:8000',
  //mainUploadUrl: 'http://127.0.0.1:8000',
  //mainApiUrl: 'http://148.113.143.59:11200',
  //mainUploadUrl: 'http://148.113.143.59:11200',
  mainApiUrl: 'https://vps103393.serveur-vps.net/api',
  mainUploadUrl: 'https://vps103393.serveur-vps.net/api',
  clientId: '30ULP67kb4tZkp4mTYeXD0eRSZZuXam4Im2YpaRs',
  clientSecret: 'qwoDRlSe8WMyEF1yDr4OZ40afEYPClCNjgNV4W7n6zszVWrkqUU8C8hXNNTC6ZzEJPspiMcpotajFg6AAG1EH3F6WjNdxuG4Tc7Lycg0HUO9MyYshSSPcDBAksib9ML2'
} ;
