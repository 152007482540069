<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          mat-raised-button
          (click)="minimizeSidebar()"
          class="btn btn-just-icon btn-white btn-fab btn-round"
        >
          <i class="material-icons text_align-center visible-on-sidebar-regular"
            >more_vert</i
          >
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
            >view_list</i
          >
        </button>
      </div>
      <h3>&nbsp;{{ getTitle() }}</h3>
    </div>
    <button
      mat-button
      class="navbar-toggler btn-no-ripple"
      type="button"
      (click)="sidebarToggle()"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
  </div>
</nav>
