import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {
  public readonly durees:Array<any> = [
    { "id" : 1, "nom" : "5 jrs" },
    { "id" : 2, "nom" : "10 jrs" },
    { "id" : 3, "nom" : "15 jrs" },
    { "id" : 4, "nom" : "21 jrs" },
    { "id" : 5, "nom" : "Plus longue durée" }
  ]
  public readonly prerequisApprenant:Array<any> = [
    { "id" : 1, "nom" : "Bon" },
    { "id" : 2, "nom" : "Acceptable" },
    { "id" : 3, "nom" : "Insuffisant" }
  ]
  public readonly valeursBool:Array<any> = [
    { "id" : true, "nom" : "Oui" },
    { "id" : false, "nom" : "Non" },
  ]
  public readonly valeursBoolInt:Array<any> = [
    { "id" : 1, "nom" : "Oui" },
    { "id" : 0, "nom" : "Non" },
  ]
  public readonly modaliteFacturation:Array<any> = [
    { "id" : 1, "nom" : "Une facture" },
    { "id" : 2, "nom" : "Deux factures" },
    { "id" : 3, "nom" : "Trois factures" }
  ]
  constructor() { }
}
