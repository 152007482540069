import { Component, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { StorageService } from './services/storage.service';
import { filter } from 'rxjs/operators';
import { MainApiService } from './services/main-api.service'
import { ReferencesService } from './services/references.service'
import { UserAuthenticatedService } from './services/user-authenticated.service'
import { environment } from 'src/environments/environment';

declare const $: any;

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, AfterContentChecked {
  private _router: Subscription;
  public title = "RACEE"
  public routeTitle:string = ""
  public errorMsg:string = ""
  public infoMsg:string = ""

  constructor(
    public router:Router,
    public mainApiService:MainApiService,
    public referencesService:ReferencesService,
    public userAuthenticated:UserAuthenticatedService,
    protected changeDetector: ChangeDetectorRef,
    protected storage:StorageService
  ) {
  }
  scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(this.scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };
  ngAfterContentChecked() : void {
      this.changeDetector.detectChanges();
  }
  ngOnInit() {
      this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
    }
    public documentWeb() {
      var nom = "connecte"
      if(this.router.url == '/login') {
        nom = 'non_connecte'
      }
      return nom;
    }
    public showError(message:string) {
     $.notify({
        icon: "add_alert",
        message: message
        }, {
        type: 'danger',
        timer: 1000,
        placement: {
          from: "top",
          align: "center"
        }
      });
    }
    public showInfo(message:string) {
      $.notify({
         icon: "add_alert",
         message: message
         }, {
         type: 'success',
         timer: 1000,
         placement: {
           from: "top",
           align: "center"
         }
       });
    }
  
    public gotoUrl(path:string, queryParams?:any, extras?:any) {
      this.showLoadingBar();
      if(queryParams !== null && queryParams !== undefined) {
        if(extras === null || extras === undefined) {
          extras = {} ;
        }
        extras.queryParams = queryParams
      }
      this.router.navigate([path], extras).then(
        (success) => {
          this.hideLoadingBar()
          // console.log("Done")
        },
        (error) => {
          this.hideLoadingBar()
          // console.log("Err")
        }
      )
    }
    public async autoLogonUser():Promise<boolean> {
      if(this.userAuthenticated.found()) {
        return true
      }
      let ok = false
      let oToken = this.storage.getData("oToken");
      if(oToken !== null && oToken !== undefined) {
        this.mainApiService.setAccessToken(oToken);
        await(this.mainApiService.authenticatedAgent(
          (data:any) => {
            this.userAuthenticated.login(data);
            // console.log(data)
            ok = true
          },
          (errorMsg:string, error:any) => {
            this.mainApiService.clearAccessToken();
            this.storage.removeData("oToken");
            this.userAuthenticated.logout()
            ok = false
          }
        ));
      }
      if(! ok) {
        this.gotoLogin()
      }
      return ok
    }

    public gotoLogin() {
      this.router.navigate(['/pages/login']);
    }

    public classMainContent() {
      if(this.documentWeb() == 'non_connecte') {
        return "col d-flex justify-content-center";
      }
      return "col";
    }
    public showLoadingBar() {
    }
    public hideLoadingBar() {
    }
  
    public openCanvas(menu:any) {
      // this.offcanvasService.open(menu, { ariaLabelledBy: 'offcanvas-basic-title' })
    }
  
    public uploadUrl() {
      return environment.mainUploadUrl
    }
    public dateParam(dateVal:any):string {
      if(dateVal == null || dateVal == '') {
        return ''
      }
      let d = new Date(dateVal),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }
}
