<nav
  class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute"
  color-on-scroll="500"
>
  <div class="container">
    <div class="navbar-wrapper">
      <a class="navbar-brand d-none d-sm-none d-md-block"
        ><img src="assets/img/favicon.png" border="0" />&nbsp;&nbsp;Bienvenue
        sur la Base de données du ANCEE - RACEE</a
      >
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
